@import '../colors.scss';
@import '../sizing.scss';

.customDayPicker {
    background: $paperwhite;
    color: $charcoal;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
}

.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}