$spacing-s: 10px;
$spacing-m: 20px;
$spacing-l: 40px;

.font-xs {
  font-size: 10px;
}
.margin-right-s {
  margin-right: $spacing-s;
}
.margin-bot-s {
  margin-bottom: $spacing-s;
}
.margin-right-m {
  margin-right: $spacing-m;
}
.margin-bot-m {
  margin-bottom: $spacing-m;
}
.margin-right-l {
  margin-right: $spacing-l;
}
.margin-bot-l {
  margin-bottom: $spacing-l;
}
.margin-s {
  margin-bottom: $spacing-s;
  margin-top: $spacing-s;
}
.margin-m {
  margin-bottom: $spacing-m;
  margin-top: $spacing-m;
}
.margin-l {
  margin-bottom: $spacing-l;
  margin-top: $spacing-l;
}
