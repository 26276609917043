$charcoal: #1D1C1C;
$green: #36C2B4;
$gray: #909090;
$lightgray: #c0c0c0;
$paperwhite: #F9F9FB;
$orange: #F15A37;
$yellow: #FFDC00;
$babyblue: #3A90C8;
$purple: #1A034A;
$transparent: rgba(#FFF,0);
