@import '../colors.scss';
@import '../sizing.scss';

.notificationPanel {
  position: fixed;
  z-index: 1000;
  right: 10px;
  top: 30px;
}


