// Override default variables before the import

@import '../colors.scss';
@import '../sizing.scss';

.normal {
  color: $charcoal;
}

.warning {
  color: $orange;
}

.needToKnow {
  font-family: 'Publico Headline Web';
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 2px;
  color: $gray;

  &>span {
    text-transform: capitalize;
  }
}

.pieChartLegend {
  display: flex;
  align-items: center;
  gap: 16px;
}

.pieChartLegendItem {
  display: flex;
  align-items: center;
}

.summaryTitle{
  height: 45px;
  letter-spacing: 0.2rem;
  text-align: center;
  text-transform: uppercase;
}

.agpTitle{
  height: 45px;
  letter-spacing: 0.08rem;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Publico Headline Web';
  font-size: 24px;
}

.summaryHEI{
  height: 45px;
  letter-spacing: 0.2rem;
  text-align: center;
  font-weight: bold;
  text-transform: capitalize;
}

.summaryText{
  font-size: 9px;
  text-align: center;
}

.summaryCol {
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;
  justify-content: flex-start !important;
}

.nutrientRow {
  height: 60px;
}

.nutrientCol {
  padding: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;

  &> .value {
    font-family: 'Publico Headline Web';
    font-size: 40px;
  }
  &> .name{
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
}

.summary {
  height: 500px;
}

.col.freqSection {
  padding: 0;
  margin: 1px;
  border-bottom: 4px solid;
  border-bottom-color: $gray;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;

  &.high {
    color: $orange;
    border-bottom-color: $orange;
  }

  &.normal {
    color: $green;
    border-bottom-color: $green;
  }

  &.low {
    color: $gray;
    border-bottom-color: $gray;
  }
}

.freqLabel {
  color: $charcoal;
}

.freqValue {
  font-size: 24px;
  margin-top:5px;
  margin-bottom:8px;
}

.timeInRangeBox {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: 240px;
}

.timeInRangeRowContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
}

.timeInRangeRow {
  display: flex;
  align-items: flex-end;
  border-bottom: 2px dotted #E0E0E0;
  justify-content: space-between;
}

.timeInRangeLabelGroup {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.timeInRangeLabel {
  font-weight: 600;
}

.timeInRangeSubLabel {
  font-size: 10px;
  padding-bottom: 2px;
}

.qEditor{
    height: 250px;
    margin-bottom: 45px;
    font-family: 'geomanist' !important;
}
.ql-container {
  font-family: 'geomanist' !important;
}
.ql-editor > p, li {
  font-size: 14.4px;
  letter-spacing: 0.7px;
  line-height: 20px;
  text-transform: none;
}