// Override default variables before the import

@import '../colors.scss';
@import '../sizing.scss';

.nutrientRow {
  height: 60px;
}

.nutrientCol {
  padding: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;

  &> .value {
    font-family: 'Publico Headline Web';
    font-size: 40px;
  }
  &> .name{
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }
}

.titleClamp {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
};

.cardClamp {
  max-width: 353px;
  width: 353px;
  min-height: 506px;
  max-height: 506px;

  svg.MuiSvgIcon-root {
    font-size: 30px;
  }
};

.cardClampSelected {
  outline-color: #ab47bc;
  outline-style: solid;
  max-width: 353px;
  width: 353px;
  min-height: 506px;
  max-height: 506px;

  svg.MuiSvgIcon-root {
    font-size: 30px;
  }
};