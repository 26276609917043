@import '../colors.scss';

.pageLayout {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: 800px;
}

.centerLayout {
  flex-direction: column;
  justify-content: center;
  display: flex;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: 800px;
}

.headerSection {
  flex-grow: 0;
  width: 100%;
  margin: 30px;
}

.headerLogo {
  width: 35px;
  height: 35px;
  margin-top: 25px;
  margin-right: 20px;
}

.headerTitle {
  display: inline-block;
  font-size: 20px;
  letter-spacing: 0.67px;
}

.headerTitleLarge {
  font-family: "Publico Headline Web";
  display: inline-block;
  position: relative;
  top: 12px;
  font-size: 48px;
  letter-spacing: 0.67px;
}

.bodySection {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.invertPage {
  background: $green;
  color: $charcoal;
}

.logoutBtn {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 1;
}

.menuBtn {
  position: absolute;
  top: 5px;
  right: 110px;
  z-index: 1;
}

.patientMenuBtn {
  color: $gray;
  border: solid $gray 1px;
  border-radius: 18px;
  width: 30px;
  height: 30px;
  line-height: 28px;
  padding-left: 6px;
  margin-left: 9px;
}

.helpBtn {
  border-radius: 12px;
  position: absolute;
  top: 5px;
  right: 110px;
  z-index: 2;
}

.emailLink:hover{
  text-decoration: underline;
}

.reportBtnLink {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3px;
  text-underline-offset: 1px;
}