// Override default variables before the import

@import '../colors.scss';
@import '../sizing.scss';


.patient {
  & > .row {
    border-bottom: $charcoal solid 1px;
  }
}

.panelCol {
  border-right: $charcoal solid 1px;
  padding-left: 30px;
  padding-right: 30px;

  &.name {
   flex-grow: 7;
  }

  &.clinic {
   flex-grow: 3;
  }

  &.date {
   flex-grow: 5;
  }

  &.last {
    border-right: 0;
  }
}

.header {
  align-content: center;
  min-height: 120px;
}

.backToList {
  font-size: 12px;
  margin-top: -6px;
  cursor: pointer;
  border-bottom: 0;
  text-transform: uppercase;
}

.menuCol {
  flex-direction: row;
  height: 75px;
  display: flex;
  align-items: center;

  &.last {
    flex-grow: 0;
  }
}

.menuitem {
  // min-width: 110px;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2rem;
  height: 100%;
  margin-left: 15px;
  margin-right: 20px;
  text-transform: uppercase;

  &.active {
    font-weight: bold;
  }
}

.patientMenuIcon {
  cursor: pointer;
  color: $gray;
  border: solid $gray 1px;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userEditMenuIcon {
 padding-left: 3px;
}