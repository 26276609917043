@import '../colors.scss';
@import '../sizing.scss';

.headerRow {
  font-family: geomanist;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 0.5px solid $lightgray;
  border-bottom: 0.5px solid $lightgray;
  text-transform: uppercase;
  align-items: center;
  display: flex;

  &>.col {
    letter-spacing: 5px;
  }
}

.dataRow {
  font-family: 'Publico Headline Web';
  font-size: 20px;
  padding-top: $spacing-l;
  padding-bottom: $spacing-l;
  border-bottom: 0.5px solid $lightgray;
}

.select.col {
  overflow: hidden;
  flex-grow: 0;
}
.patient.col {
  overflow-x: hidden;
  flex-grow: 5;
}
.feeling.col {
  overflow: hidden;
  flex-grow: 2;
}
.goals.col {
  overflow: hidden;
  flex-grow: 5;
}
.flags.col {
  overflow: hidden;
  flex-grow: 2;
}

.emoticon {
  vertical-align: text-bottom;
  margin-right: 8px;
  width: 24px;
}

.patientDetail {
  font-size: 14px;
  letter-spacing: 5px;
  margin-top: $spacing-s;
  margin-bottom: $spacing-s;
}

.filterPanel {
  display: flex;
  font-family: geomanist;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  align-items: center;

  > .md-form {
    margin: 0;

    > .form-control {
        font-size: 16px;
        color: black;
        font-family: geomanist;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.25rem;
        margin-bottom: 1px;
    }

    > input:focus::placeholder {
      color: $lightgray;
    }

    > input::placeholder {
      color: $lightgray;
    }
  }
}

.filterPanelLast {
  position: absolute;
  right: $spacing-m;
  text-align: right;
  bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
}

.clinicInviteCode {
  margin-bottom: 18px;
}

.filterMenuItem {
  font-size: 12px;

  & > i {
    color: white;
    margin-left: 6px;
  }

  &.active {
    color: $green;
    background-color: white;

    & > i {
      color: $green;
    }
  }
}

.feelingNeutral{
  color: $charcoal;
}

.filterRow {
  background-color: $green;
  padding-top: $spacing-m;
  padding-bottom: $spacing-m;
  font-family: geomanist;
  font-weight: bold;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
}

.clearFilter {
  cursor: pointer;
  position: relative;
  top: -2px;
}

.flagIcon {
  margin-right: 20px;
  color: $lightgray;

  &.active {
    color: $orange;
  }
}

.patientEditButton {
  cursor: pointer;
  font-size: 14px;
}

.patientEditText {
  cursor: pointer;
  font-size: 24px;
}

.patientSubscription {
  font-size: 11px;
  letter-spacing: 3px;
  margin-top: $spacing-s;
  margin-bottom: $spacing-s;
}

.clinicianEditMenuBtn {
  cursor: pointer;
  position: relative;
  font-size: 32px;
  text-align: right;
}

.colClinician {
  font-size: 32px;
  text-align: right;
}
