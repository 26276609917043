@import '../colors.scss';

.patient>.row.goals {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.newGoalButton {
  font-family: 'Publico Headline Web';
  font-size: 24px;
  color: $green;
  cursor: pointer;
}

.goalTypeButton {
  letter-spacing: 0.2rem;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  margin-left: 0;
  color: $green;
}
.goalTypeButton.delete {
  color: #F15A37;
}
.goalTypeButtonDisabled {
  color: gray;
  cursor: default;
  letter-spacing: 0.2rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-left: 0;
}

.newNutrientGoal {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.addGoalCol {
  align-items: center;
  display: flex;
}
.addGoalCol .md-form{
  margin: 0;
}

.GoalCol {
  align-items: center;
}

.addGoalChange {
  margin-right: 20px;
  width: 10%;
}

.addGoalTime {
  width: 10%;
}

.addGoalNutrient {
  margin-right: 20px;
  width: 15%;
}

.addGoalAmount {
  width: 150px;
  margin-bottom: 0 !important;
}

.addGoalOf {
  margin-left: 20px;
  margin-right: 20px;
}

.addGoalButton {
  width: 30px;
  margin-left: 0;
}

.addGoalFor {
  margin-left: 0;
  margin-right: 20px;
}

.addGoalSelect {
  margin-bottom: 8px;
  margin-top: 8px;
  height: 54.5px;
}

.goalTitle {
  font-size: 14px;
  letter-spacing: 0.2rem;
}

.goalDetail {
  font-size: 30px;
}

.goalTypeTitle {
  margin-top: 24px;
  padding-left: 15px;
}

.goalNote {
  font-size: 14px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.goalsList{
  padding: 0 15px;
  flex-flow: column;
}
.goalListTable{

}
.goalsList table{
  margin-bottom: 30px;
}

.goalsList table tr th,
.goalsList table tr td {
  border-bottom: 1px solid rgba(0,0,0,0.2);
  padding: 1rem 0;
}

.goalsList table tr th {
  color: rgba($color: #000000, $alpha: 0.5);
}
.errorMessage:empty {
  margin: 0;
}