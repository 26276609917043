@import '../colors.scss';
@import '../sizing.scss';

.fullModal {
  &> .modal-content {
    background-color: transparent;

    &> .modal-body {
      padding: 0;
    }
  }
}

.mealModalImageCol {
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  background: $charcoal;
  height: 100vh;
  flex-grow:7;
  flex-direction: row;
}

.modalCloseButton {
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
}

.mealModalImage {
  display: block;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}

.mealModalInfoCol {
  align-items: center;
  display: flex;
  background-color: rgba(255,255,255,1);
  height: 100vh;
  flex-grow:5;
}

.mealModalCloseBtn {
  z-index: 1000;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
}

.mealModalEditBtn {
  z-index: 1000;
  text-align: right;
  position: absolute;
  top: 0;
  right: 50px;
  cursor: pointer;
}


.mealModalTitle {
  text-transform: capitalize;
  letter-spacing: 0.2rem;
}

.mealModalSummary {
  font-size: 14px;
  letter-spacing: 0.2rem;
}

.mealModalEditBtns {
  i {
    margin-top: 10px;
  }
}

.mealModalNumItemsRow {
  margin-top: $spacing-l;
}

.mealModalNumItems {
  font-size: 14px;
  letter-spacing: 0.2rem;
  font-weight: bold;
  margin-bottom: $spacing-m;
}

.mealModalThumb{
  display: block;
  max-width: 72px;
}

.mealModalItemName {
  font-size: 24px;
  text-transform: capitalize;
}

.mealModalItemQuantity {
  font-size: 14px;
  letter-spacing: 0.2rem;
}

.mealModalItem {
  align-items: center;
  margin-bottom: $spacing-m;
  cursor: pointer;
}

.mealBorder{
  border-style: dotted;
}
