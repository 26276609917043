// Override default variables before the import

@import './colors.scss';
@import './sizing.scss';

$font-family-base: geomanist;
$headings-font-family: 'Publico Headline Web';
$body-bg: $paperwhite;
$enable-rounded: false;

$link-color: inherit;
$link-decoration: none;
$link-hover-color: inherit;
$link-hover-decoration: none;

$primary: $green;
$primary-color: $green;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

$roboto-font-path: '../../font/roboto/';
$image-path: '../../img/';
$input-md-focus-color: $gray;
$mdb-font-family: geomanist;
@import '~mdbreact/dist/scss/mdb.scss';

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: 0.065rem
}

p {
  font-size: 10px;
  letter-spacing: 2.5px;
  line-height: 18px;
  text-transform: uppercase;
}

.btn {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 4.5px;
  line-height: 14px;
  text-transform: uppercase;
  height: 64px;
  margin: 0;
}

.btn-link {
  color: $charcoal;
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 2.5px;
  line-height: 18px;
  padding-bottom: 1px;
  border-bottom: $charcoal 1px solid;

  @include hover {
    border-bottom: $transparent 1px solid;
  }
}

.md-form.md-bg {
  &>input[type=text],
  &>textarea.md-textarea,
  &>input[type=email],
  &>input[type=password] {
    background-color: $paperwhite;
    border-radius: 0;
    border: 0.3px solid $gray;
    font-family: 'Publico Headline Web';

    &:focus:not([readonly]) {
      background-color: $paperwhite;
      border-bottom: 0.3px solid $gray;
    }

    &.error {
      border-color: $orange;
    }

    &.error+label {
      color: $orange
    }
  }
}

.custom-select {
  border: 1px solid $gray;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 50px;
}

.md-form.md-bg.form-lg > label {
  left: 12px;
  padding: 0;

  &.active {
    transform: translateY(-4px) scale(0.6);
    font-size: inherit;
    font-weight: inherit;
  }
}

.error {
  color: $orange;
}

.errorMessage {
  color: $charcoal;
}

.profile-picture {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 41px;
  height: 118px;
  width: 118px;
  display: block;
}

.short-bio {
  height: 131px !important;
}

.PrivateNotchedOutline-root-60 {
  border-radius: 0 !important;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiChip-label {
  font-family: 'geomanist';
}
.MuiAutocomplete-root{
  margin-top: -15px;
}
.fa-stack {
  margin-right: 10px;

  &>i:first-child {
      color: $green;
      text-shadow: 3px 3px 5px $lightgray;
  }
  &>i:last-child {
      color: $charcoal;
  }

}

.mealDateDisplay {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fill, 30px);
  padding: 5px 0px 30px 5px;
}

input {
  accent-color: $green;
}
