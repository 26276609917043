
.timeline-day {
    margin: 24px 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #c5c5c5;
}

.timeline-container > .timeline-day:first-child{
    margin-top: -60px;
}

.tagify{
    flex-wrap: nowrap;
}
.rounded-btn{
    cursor: pointer;
    color: #FFF;
    background-color: #36C2B4;
    border-radius: 18px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
}
.timeline-content{
    margin: 20px 0;
    padding: 0 10px;
}
.toolbar{
    position: sticky;
    top: 0;
    background-color: #fff;
    right: 0;
    z-index: 100;
    padding: 10px 0;
    border: 0 !important;
}
.toolbar .MuiFormControl-root{
    margin-top: 0 !important;
}
.toolbar .MuiInputBase-formControl{
    max-height: 40px;
    max-width: 200px;
}
.toolbar .MuiInputBase-root fieldset{
    border-radius: 0;
}
.tools-wrapper{
    gap: 12px;

}
.date-wrapper span{
    margin: 0 6px;
    color: #a5a5a5;
    font-size: 12px;
}
.timeline-header{
    padding: 10px;
    display: inline-flex;
    position:sticky;
    top: 0;
    z-index: 101;
}
.rx-tag {
    background-color: #eaeaea;
    color: var(--gray);
    padding: 3px 6px;
    margin: 4px;
    border-radius: 4px;
    cursor: pointer;
}
.rx-tag-active {
    background-color: var(--dark);
    color: var(--light);
}
.search-dropdown {
    background-color: #fff;
    position: absolute;
    top: 150%;
    right: 0;
    display: none;
    width: 400px;
}
.search-items {
    min-width: 200px;
    padding: 16px;
    border: 1px solid var(--gray);
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);
}
.search-dropdown.active{
    display: block;
}
.selected-filters{
    position: relative;
    border: 1px solid #909090;
    max-width: 360px;
    padding: 0px 8px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    height: 40px;
    align-items: flex-start;
}
.bigger-box{
  height: 60px;
  z-index: 100;
  background-color: #fff;
}
.scroll-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}
.scroll-loader .loading-container{
    padding: 0;
}
.scroll-loader .lds-dual-ring::after{
    width: 42px;
    height: 42px;
}
