.currentloggingTrialTable{
    flex-direction: column;
}
.delete{
    color: #F15A37;
}

.subscriptionBtn{
    letter-spacing: 0.2rem;
    cursor: pointer;
}
.currentloggingTrialTable table{
    margin-bottom: 30px;
}

.currentloggingTrialTable table tr th,
.currentloggingTrialTable table tr td {
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 1rem 0;
}

.currentloggingTrialTable table tr th {
    color: rgba($color: #000000, $alpha: 0.5);
}
.deleteDisabled{
    pointer-events: none;
    color:#808080
}