@import '../colors.scss';

.patient > .row.goals {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.newGoalButton {
  font-family: 'Publico Headline Web';
  font-size: 24px;
  color: $green;
  cursor: pointer;
}

.goalTypeButton {
  letter-spacing: 0.2rem;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  padding-left: 5px;
  margin-right: 5px;
  line-height: 1.6;
}

.newNutrientGoal {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.addGoalCol {
  align-items: center;
  display: flex;
}

.GoalCol{
  align-items: center;
}

.addGoalChange {
  margin-right: 20px;
}

.addGoalAmount {
  width: 150px;
}

.addGoalOf{
  margin-left: 20px;
  margin-right: 20px;
}

.addGoalButton {
    width: 30px;
    margin-left: 30px;
}

.addGoalNutrient {
}

.addGoalFor {
  margin-left: 0;
  margin-right: 20px;
}

.addGoalSelect {
  margin-bottom: 8px;
  margin-top: 8px;
    height: 54.5px;
}

.goalTitle {
    font-size: 14px;
    letter-spacing: 0.2rem;
}

.goalDetail {
    font-size: 30px;
}
