.labelUpper {
  font-family: geomanist;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.labelValue {
  font-family: 'Publico Headline Web';
}

.labelG {
  font-family: geomanist;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.labelS {
  font-size: 12px;
}

.lableM {
  font-size: 20px;
}

.labelL {
  font-size: 40px;
}

.font-wide {
  letter-spacing: 0.33rem;
}

