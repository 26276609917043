@import '../colors.scss';
@import '../sizing.scss';

.recipe-search-modal {
  max-width: 80% !important;
}

.custom-recipe-search-modal {
  max-width: 84% !important;
}

.autopilot-checkbox-group-label {
  font-size: 16px;
  font-weight: 600;
}

.autopilot-checkbox-group {
  font-size: 14px;
  width: fit-content;
  height: 105px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.autopilot-checkbox {
  width: 210px;
}