@import '../colors.scss';

.patientNutrientTable {
    margin: 0;
}

.patientNutrientRow {
    overflow-x: scroll;
    padding: 0;
}

.table thead th {
    font-size: 11px;
    letter-spacing: 0.2rem;
    color: $charcoal;
    border-bottom: 1px solid $charcoal;
}

.table thead td {
    font-size: 14px;
    color: $charcoal;
    border-bottom: 1px solid $charcoal;
}

.table tfoot td {
    font-size: 14px;
    color: $charcoal;
    font-weight: bold;
    border-bottom: 1px solid $charcoal;
}

.firstCol {
    padding-left: 30px !important;
}

.lastCol {
    padding-right: 30px !important;
}

.oddCol {
    background-color: #ffffff;
}

.totalRow {
    background-color: #ffffff;
    font-weight: bold;
    color: $green;
}
.patientNutrientTable tr { display: block; float: left; }
.patientNutrientTable th, .patientNutrientTable td { display: block; }
