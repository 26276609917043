.meal-item{
    display: inline-block;
}
.meal-card{
    padding: 10px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #c5c5c5
}
.activity-card{
  width: 400px;
  padding: 10px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #c5c5c5
}
.meal-card-icon {
    border-radius: 18px;
    width: 36px;
    height: 36px;
    text-align: center;
}
.food-details{
    max-width: 225px;
    flex: 1 1 auto;
}

.carbs-cals-count {
    margin-bottom: -5px !important;
}
