@import '../colors.scss';

.meal {
  width: 100px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.meal-deleted-true {
  opacity: 0.5;

  .fa-ban {
    font-size: 80px;
    margin-left: 6px;
    margin-top: 20px;
  }
}

.mealNameContainer {
  position: relative;
}

.mealNameHeader {
  position: absolute;
  top: 20px;
  left: 30px;
}

.mealImageContainer {
  align-items: center;
  display: flex;
}

.mealImageContainerBorder {
  align-items: center;
  display: flex;
  border: 4px solid $green;
  width: min-content;
}

.mealImage {
  max-height:120px;
  max-width: 120px;
}
.range-graph{
  margin: 4px 0;
}

.mealText {
  font-size: 13px;
  letter-spacing: 0.15rem;

  &.hours {
    font-weight: bold;
  }
}

.logRow {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: solid $charcoal 1px;
}

.mealRow {
  border-bottom: solid $charcoal 1px;
}

.logFilterPanel {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.2rem;
  // padding-left: 15px;
  padding-right: 15px;
  background-color: $green;
  align-items: center;
}

.logFilterCol {
  flex-direction: row;
  height: 75px;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-basis: auto;
  width: auto;
  // margin-right: 25px;
}

.logFilter {
  font-weight: bold;

}

.logEmptyRow {
  padding: 30px;
}

.printOnly {
   display : none;
}

@media print {
    .printOnly {
       display : block;
    }
}
