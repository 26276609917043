@media print {
    @page {
        margin: 0;
        size: auto;
    }

    html, body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
    }

    .ReportPage {
        page-break-after: always;
    }

    .ReportSection {
        page-break-inside: avoid;
    }
}

@media screen {
    .ReportWrapper {
        width: 11in;
        margin-left: auto;
        margin-right: auto;
    }
}

.ReportWrapper {
    background-color: white;

    header {
        text-align: center;
        margin-bottom: 10px;
        page-break-after: avoid;
    }

    h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 20px;
        margin: 10px 0;
    }

    strong {
        font-weight: bold;
    }

    div, p, td {
        letter-spacing: 1.8px;
        font-size: 10px;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        margin-bottom: 10px;

        thead {
            background-color: black;

            th {
                font-size: inherit;
            }

            th,
            td {
                color: white;
            }

            tr {
                border: 1px solid black;
            }

            td {
                padding: 5px 10px;
            }

            h2 {
                margin: 0;
                text-align: center;
            }
        }

        tr {
            border: 1px solid #E0E0E0;
            vertical-align: top;
        }

        th,
        td {
            padding: 10px 5px;

            &:first-child {
                padding-left: 10px;
            }

            &:last-child {
                padding-right: 10px;
            }
        }

        th {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }

}

.ReportDot {
    width: 30px;
    height: 30px;
    font-size: 14px !important;
    border-radius: 50%;
    padding-top: 4px;
    display: flex;
    align-content: center;
    justify-content: center;
    padding-left: 2px;
    font-weight: bold;
}

.lightGreyText {
    color: #1D1C1C;
    opacity: 0.6;
}

.reportSubheading {
    color: #7D7D7D;
    font-size: 12px;
    letter-spacing: 2px;
}

.ReportSection {
    padding: 40px;
}

.reportLightGreyBg {
    background-color: #F9F9FB;
}

.disclaimer {
    font-size: 7px !important;
}

.CGMPostFoodGlucoseRise {
}

.nutrientBreakdownBar {
    .labels > * {
        display: inline-block;
    }

    .bars > * {
        display: inline-block;
        height: 20px;
        color: black;
        font-family: 'Publico Headline Web';
        padding-top: 3px;
        font-size: 10px;
    }
}