.mealview-modal .modal-dialog{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    height: 100% !important;
    max-width: 100% !important;
    width: 70%;
}
.mealview-modal .modal-body{
    padding:30px;
}
.modal.show .modal-dialog{
    transform: translateX(100%) !important;
}
.modal.fade .modal-dialog{
    transform: none !important;
}
.mealview-modal .modal-content{
    min-height: 100%;
}
.edit-btn, .trash-btn{
    font-size: 22px;
    color: #36C2B4;
}
.close-btn{
    font-size: 24px;
    color: #626262;
}
.edit-btn, .close-btn, .trash-btn{
    cursor: pointer;
}
.bx-1{
    border-top: 1px solid grey;
}
.datepicker.MuiFormControl-root{
    margin: 0 !important;
}
.datepicker .MuiInputBase-formControl{
    max-height: 40px;
}
.datepicker .MuiInputBase-formControl fieldset{
    border-radius: 0;
}
.addMealBtn{
    font-family: 'Publico Headline Web';
    cursor: pointer;
}
.meal-inputs .md-form {
    margin: 0 !important;
}
.meal-inputs .md-form.md-bg .form-control{
    padding: 0.7rem !important;
}
.meal-inputs .MuiFormControl-root {
    margin-top: 0 !important;
}
.meal-inputs .MuiAutocomplete-root{
    width: 350px;
}

.MuiFormLabel-root.Mui-focused {
  color: #36C2B4 !important;
}