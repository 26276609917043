.waterLogCard{
    flex: 0 auto;
    padding: 4px 8px;
    gap: 8px;
    border: 2px solid transparent;
}
.waterLogCard:hover{
    border: 2px solid #a2a2a2;
}
.waterLogCard > .waterbtn{
    visibility: hidden;

}
.waterLogCard:hover > .waterbtn{
    visibility: visible;
}
.water-btn, .water-plus, .water-minus{
    cursor: pointer;
}