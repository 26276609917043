.MealSummaryRowTable {
    th,
    td {
        padding: 0.04rem 0.3rem !important;
        font-size: 5pt !important;
        letter-spacing: 1.2px !important;
    }
    td div {
        font-size: 5pt !important;
        letter-spacing: 1.2px !important;
    }

}

.MealOptionsSummaryOverviewTable {
    th,
    td {
        padding: 0.2rem 0.5rem !important;
    }
}