.mindful-eating-survey-icon {
  box-sizing: border-box;
  padding: 4px;
  margin-left: 14px;
  margin-top: -10px;
  width: 35px;
  border: 1px solid #C5C5C5;
  background-color: #FFF;
  border-radius: 3px;
}

.patient-survey-container {
  padding: 50px 0;
  max-width: 565px;
}

.user-survey-input-buttons {
  display: flex;
  justify-content: left;
  gap: 6px;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 5px;
}

.question-container {
  margin: 45px 0;
}

.question-label {
  margin-left: 5px;
}

.slider-container {
  margin: auto;
  padding: 20px 4px 5px 10px;
  max-width: 565px;
}

.slider-labels-container {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-left: 6px;
}

.slider-label-start {
  width: 180px;
  align-items: flex-start ;
  text-align: left;
}

.slider-label-end {
  width: 180px;
  align-items: flex-end;
  text-align: right;
}

.slider-label-middle {
  margin-left: 6px;
  text-align: center;
}

