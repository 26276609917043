@import '../colors.scss';

.patient > .row.goals {
  font-size: 14px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.patientDataTable {
    margin: 0;
}

.newGoalButton {
  font-family: 'Publico Headline Web';
  font-size: 24px;
  color: $green;
  cursor: pointer;
}

.newNutrientGoal {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.linkTitle {
    font-size: 14px;
    letter-spacing: 0.2rem;
}

.goalDetail {
    font-size: 30px;
}
